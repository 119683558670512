<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'home-agentscout' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Home
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
        
    <div class="text-center my-3" v-if="Caricato == false">
      <b-spinner  v-if="errore_caricamento == false"
          variant="primary"
          style="width: 3rem; height: 3rem;"
          label="loading"
      />
      <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
    </div>

    <b-card bg-variant="Default" v-if="Caricato">
      <div class="text-right">
        <b-button variant="outline-primary" class="px-1 mb-1" @click.prevent="clickReset" v-if="resetState != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
      </div>

        <!-- table -->
        <vue-good-table
        mode="remote"
        ref="table"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :rows="rows"
        :columns="columns"
        :isLoading=isLoading
        :search-options="{ 
            enabled: true,
            externalQuery: searchTerm }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        >
        
        <template slot="loadingContent">
          <div class="text-center my-3">
              <b-spinner
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
              />
              <div class="d-block pt-1 text-primary">Caricamento</div>
          </div>
        </template>
        
        <div slot="emptystate"> <div class="text-center py-4"> Nessun dato disponibile </div> </div>

        <template
            slot="table-row"
            slot-scope="props"
        >

            <span v-if="props.column.field === 'amount'">
              {{ prezzoFormattato(props.formattedRow[props.column.field]) }}
            </span>

            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                <span v-if="props.row.status == 'paid'">Pagato</span>
                <span v-else>Non pagato</span>                
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'updated_at'">
                {{ formattazioneData(props.formattedRow[props.column.field], 'it') }}
            </span>

            <span v-else-if="props.column.field === 'api_balance_transaction_id'">
              <span @click.stop>
                <div class="text-nowrap">
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="cursor-pointer text-secondary"
                    v-b-tooltip.hover.v-primary
                    title="Azioni"
                    @click="visualizzaTransazioni(props.row.id, props.row.api_payout_id, props.row.updated_at, props.row.amount, props.row.status)"
                  />
                </div>
              </span>
            </span>

            <span v-else-if="props.column.field === 'api_payout_id'">
                <a @click.prevent="apriStripeDettaglioBonifico(props.formattedRow[props.column.field])">
                    {{ props.formattedRow[props.column.field] }}
                </a>
            </span>

            <!-- Column: standard -->
            <span v-else>
            {{ props.formattedRow[props.column.field] }}
            </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                Visualizzazione da 1 a 
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['10','25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> di {{ props.total }} righe </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>
            
    </b-card>

    <b-modal
      ref="modal"
      size="xl"
      centered
      hide-footer
      :title="this.openModal.titolo"
      @hide="resetTransazioniModal"
    >
      <b-row class="py-1">
        <b-col md="3" lg="2">
          <p>Importo: {{ prezzoFormattato(this.openModal.amount) }}</p>
        </b-col>
        <b-col md="9" lg="10">
          <p>Stato: 
            <b-badge :variant="statusVariant(this.openModal.status)">
              <span v-if="this.openModal.status == 'paid'">Pagato</span>
              <span v-else>Non pagato</span>   
            </b-badge>
          </p>
        </b-col>
      </b-row>
      
      <b-table responsive="lg" :items="this.openModal.dati_tabella"/>

    </b-modal>

  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BModal, BTable, VBToggle, VBTooltip
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { prezzoFormattato } from '@core/utils/funzioniGlobali' 

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BCard, 
    BCardText,
    BRow, 
    BCol, 
    BBadge,
    BPagination,
    BSpinner,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton, 
    BModal, 
    BTable, 
  },
  setup() {
      return {
        prezzoFormattato, 
      }
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      emptystate: true, 
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Id Bonificio',
          field: 'api_payout_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Stato',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
            filterDropdownItems: [
                { value: 'paid', text: 'Pagato' },  
                { value: 'unpaid', text: 'Non pagato' }, 
            ],
          },
        },
        {
          label: 'Importo',
          field: 'amount',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Data',
          field: 'updated_at',
          html: true,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: '',
          field: 'api_balance_transaction_id',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows: [],
      searchTerm: '',
      resetState: 'hidden',
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      startServerParams: {
        columnFilters: {},
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      options_ricerca: [
        { value: null, text: 'seleziona...' },
        { value: 'amount', text: 'Importo' },
        { value: 'updated_at', text: 'Data (formato yyyy-mm-dd)' },
      ],
      campiRicerca: {
        nome: '',
        sezione: null,
      },
      infoSideBar: {
        id: '',
        status_name: '',
        license_data: [ ],
        fic_numero: '',
        fic_data: '',
        fic_url_pdf: null,
        fic_invio_email_copia_cortesia: false,
        titolo: '', 
        schema_iva: 0,
      },
      Caricato: false,
      errore_caricamento: false,

      action_press_recupera_transizioni: false,
      openModal: {
        titolo: '',
        amount: '',
        status: '', 
        dati_tabella: [], 
      },

    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        'unpaid' : 'light-warning',
        'paid' : 'light-success',
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    /*
    //in caso di resize del browser -> ricalcolo della larghezza della finestra
    window.onresize = () => {
      this.checkWindowsSizeAndResponsiveGoodTable();
    }
    */
  },
  created() {
    //this.checkWindowsSizeAndResponsiveGoodTable();
    
    //adesso faccio il caricamento dei dati
    this.loadItems();

    this.Caricato = true;
    this.errore_caricamento = false;
  },
  methods: {
    checkWindowsSizeAndResponsiveGoodTable(){
      if(window.innerWidth <1400){
        this.$set(this.columns[4], 'hidden', true)
      } else {
        //visualizza la colonna "id Bonifico"
        this.$set(this.columns[4], 'hidden', false)
      }
    },
    formattazioneDataOra(converted_at,lang) {
      if (converted_at) {
        return moment(String(converted_at)).locale(lang).format('DD/MM/YY - HH:mm')
      } else {
        return '---'
      }
    },
    formattazioneData(converted_at,lang) {
      if (converted_at) {
        return moment(String(converted_at)).locale(lang).format('DD/MM/YY')
      } else {
        return '---'
      }
    },
    ricercaGenerale(){
      //console.log("ricerca generale - chiamata Api per avere la ricerca su tutti i dati e NON solo sui dati dell'attuale viewport della tabella ;-)")
      console.log(this.campiRicerca)

      if((this.campiRicerca.sezione !== null) && (this.campiRicerca.sezione !== 'undefined')){
        console.log("ok ricerca valida")

        //aggiorna parametri con la stessa funzione del filtro colonna ;-)
        this.updateParams({
          columnFilters: {
            [this.campiRicerca.sezione]: this.campiRicerca.nome
          },
        });

        //forza reset filtro colonne
        this.$refs.table.reset();

        this.loadItems();

        //attiva bottone "reset"
        this.resetState = 'attiva';
      }
    },
    clickReset(){
      console.log("clickReset");

      //forza reset filtro colonne
      this.$refs.table.reset();

      //forza reset parametri iniziali della tabella
      this.updateParams(this.startServerParams);
      this.loadItems();

      //reset campi ricerca
      this.campiRicerca.nome = '';
      this.campiRicerca.sezione = null;
      //disattiva bottone "reset"
      this.resetState = 'hidden';
    },
    advanceFilter(val) {
      //this.searchTerm = val

      this.updateParams(val);
      this.loadItems();
    },
    
    updateParams(newProps) {
      //console.log(newProps)

      if (typeof newProps.columnFilters !== 'undefined'){
        if(typeof newProps.columnFilters.aggregato_name_surname !== 'undefined'){
          //in caso di campo con "stringa" => vai di filtro per il valore richiesto
          if(newProps.columnFilters.aggregato_name_surname != ''){
            newProps.columnFilters['user.surname'] = newProps.columnFilters.aggregato_name_surname;
          }

          //in caso di campo in cui avevo impostato un filtro e lo voglio cancellare
          if(newProps.columnFilters.aggregato_name_surname == ''){
            delete(newProps.columnFilters['user.surname']);
          }
        }

      }

      //attiva bottone "reset" filtro o ricerca
      this.resetState = 'attiva';

      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      //console.log('cambio ordinamento')
      //console.log(params)

      let field_ricerca = '';

      //colonne con relazione
      if(params[0].field == 'source.text'){
        field_ricerca = 'source.name';
      } else if(params[0].field == 'aggregato_name_surname'){
        field_ricerca = 'user.surname';
      } else {
        field_ricerca = params[0].field;
      }

      this.updateParams({
        sort: [{
          type: params[0].type,
          field: field_ricerca,
        }],
      });
      this.loadItems();
    },
    
    onColumnFilter(params) {
      //console.log("AGGIORNAMENTO FILTRO COLONNA --------")
      this.updateParams(params);
      this.loadItems();
    },

    loadItems() {
      this.isLoading = true;

      this.$http.get('api/crm/stripe/payouts/list', {
        params: this.serverParams 
        }).then(response => { 
            if(response.data.statusCode == 200){
              //ok
              this.totalRecords = response.data.reply.meta.total;
              this.rows = response.data.reply.data;
              
            } else if(response.data.statusCode == 422){
              //errori con testo di notifica ok dall'api
              this.$swal({
                title: 'Errore',
                text: 'codice errore: '+response.data.reply,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            } else {
              //tutto gli altri possibili errori
              this.$swal({
                title: 'Errore Generico',
                text: 'codice errore: '+response.data.statusCode,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            }
            
            //rimuovi il loading dopo il caricamento dei dati nella tabella
            this.isLoading = false;
        
        })

    },

    apriStripeDettaglioBonifico(codice){
        let url = "https://dashboard.stripe.com/test/payouts/"+codice;

        window.open(url, "_blank", "noopener,noreferrer"); //finestra aperta senza restrizioni e in una nuova scheda
    },

    visualizzaTransazioni(id_riga, api_payout_id, updated_at, amount, status){
      //caricamento dati da stripe del dettaglio delle transazioni contenuti in qeusto bonifico

      this.action_press_recupera_transizioni = true;

      this.$http.get('api/crm/stripe/payouts/transactions/'+id_riga).then(response => { 
          //console.log("risposta server stripe/payouts/transactions --------")
          //console.log(response.data)

          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            this.action_press_recupera_transizioni = false;

            //console.log(response.data.reply.dati.data)
            //this.open_api_ricerca_items = response.data.reply.dati.data;

            this.openModal = {
              titolo: "Bonifico del "+this.formattazioneDataOra(updated_at, 'it'),
              amount: amount,
              status: status,
              dati_tabella: response.data.reply.dati,
            }

            //apri la modale
            this.$refs['modal'].show()
            
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore durante il recupero delle informazioni delle transazioni di qeusto bonifico',
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

            this.action_press_recupera_transizioni = false;
          }
        }).catch(e => {
          console.log(e);
        });

    },
    resetTransazioniModal(){
      this.openModal = {
        titolo: '',
        amount: '',
        status: '',
        dati_tabella: [],
      }

    },
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.riga_sidebar{
  padding-bottom: 5px;
}
</style>